import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const DyingLight = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };

  const lang = "pl";

  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #E27A37, #F58136, #EE6E1B, #DA6519, #E07530 )",
        icons: "#E27A37",
        navClass: "dl1",
      }}
      seo={{
        title: "Dying Light",
        headerTitle: "dl1",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/dl1_og-image.jpg"),
      }}
      // langSwitch={{
      //     langKey: "en",
      //     langSlug: "/en/projects/dl2-stay-human/",
      // }}
    >
      <PortfolioHeader name="dl1" height="276" />
      <section className="container-fluid dl1__section2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Dying Light</h1>
              <ul>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Dying Light to absolutna legenda. Choć ten kultowy tytuł studia
                Techland ma już 6 lat to wciąż uznawany jest za jedną z
                najlepszych gier poruszających tematykę zombie apokalipsy. Co
                więcej, pomimo ogłoszenia premiery jej drugiej części, która
                jest jedną z najbardziej wyczekiwanych premier 2022 roku, gra
                nadal jest rozwijana i wydawana na kolejne platformy (ostatnio
                na Nintendo Switch), dzięki czemu społeczność zgromadzona wokół
                niej nadal się powiększa.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dl1__section3">
        <div className="container">
          <div className="row">
            <div className="col-12 dl1__list dl1__list--intro">
              <h2>Konkret</h2>
              <p>
                Naszym zadaniem było wdrożenie odświeżonej wersji oficjalnej
                strony flagowego tytułu studia Techland - DYING LIGHT.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--macbook">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/dl1_full.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="offset-md-6 col-md-6 dl1__section3--description dl1__list">
              <h2>Technologia</h2>
              <p>
                Projekt zrealizowaliśmy w oparciu o Gatsby.js. To Technologia
                znana zarówno nam jak i sprawdzona i przetestowana przez załogę
                Techland - wybór był więc oczywisty.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dl1__section4 px-0">
        <img
          className="img-fluid"
          src={require("../../assets/img/portfolio/dl1-banner.jpg")}
          alt=""
        />
        <div className="dl1__section4__img dl1__section4__img--1">
          <img
            className="img-fluid"
            src={require("../../assets/img/portfolio/dl1-lang.png")}
            alt=""
          />
        </div>
        <div className="dl1__section4__img dl1__section4__img--2">
          <img
            className="img-fluid"
            src={require("../../assets/img/portfolio/dl1-iphone.png")}
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid dl1__section5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1"></div>
            <div className="col-md-6 order-1 order-md-2 dl1__list">
              <h2>Wersje językowe</h2>
              <p>
                Stronę wdrożyliśmy w 13 wersjach językowych, a dzięki
                analizowaniu lokalizacji IP użytkowników odwiedzających stronę
                mogliśmy dla większości z nich serwować od razu właściwą wersję
                językową strony. Piece of cake.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dl1__section6 px-0">
        <img
          className="img-fluid d-none d-md-block"
          src={require("../../assets/img/portfolio/dl1-banner2.jpg")}
          alt=""
        />
        <div className="row dl1__section6__row">
          <div className="col-md-6 dl1__section6__col-left">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/dl1-screen1.png")}
              alt=""
            />
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/dl1-screen2.png")}
              alt=""
            />
          </div>
          <div className="col-md-6 dl1__section6__col-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/dl1-screen3.png")}
              alt=""
            />
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/dl1-screen4.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid dl1__section7 px-0">
        <img
          className="img-fluid"
          src={require("../../assets/img/portfolio/dl1-banner3.jpg")}
          alt=""
        />
      </section>

      <section className="container-fluid dl1__section8">
        <div className="row">
          <div className="col-md-6 dl1__list dl1__section8__col-left">
            <h2>PS.</h2>
            <p>
              Dying Light to początek ogromnego, światowego sukcesu naszego
              Klienta, ale i początek naszej przygody w świecie projektów w
              stylu zombie.Sprawdź co jeszcze zmalowaliśmy dla Techlandu:
            </p>
            <p className="dl1__section8__products">
              Dying Light 2 PREORDER<br></br>
              Dying Light 2<br></br>
              Which Monster<br></br>
              Dying Light Nintendo Switch
            </p>
          </div>
          <div className="col-md-6 dl1__section8__col-right">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/dl1-mac.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default DyingLight;
